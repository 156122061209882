import capitalize from 'lodash/capitalize';
import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';
import isEqual from 'lodash/isEqual';
import isEqualWith from 'lodash/isEqualWith';
import pick from 'lodash/pick';

import { PoolType } from 'declarations/exclusives/listing';
import { MarketLocation } from 'declarations/exclusives/market';

import {
  Filters,
  INITIAL_FILTERS,
  OriginValues,
  PROPERTY_TYPE_STATUS_ARRAY,
  PropertyTypeValues,
} from 'helpers/exclusives/filterHomes';

import { MARKET_NEIGHBORHOODS, NeighborhoodInfo } from '../../../helpers/exclusives/neighborhoods';

export const DEFAULT_MARKET_IDENTIFIER: Uppercase<MarketLocation> = 'DALLAS';

export const NEIGHBORHOODS_KEYED_BY_UPPERCASE_MARKET: Record<
  Uppercase<MarketLocation>,
  NeighborhoodInfo[]
> = Object.entries(MARKET_NEIGHBORHOODS).reduce((acc, [market, neigbhorhoods]) => {
  acc[market.toUpperCase() as Uppercase<MarketLocation>] = neigbhorhoods;
  return acc;
}, {} as Record<Uppercase<MarketLocation>, NeighborhoodInfo[]>);

export const bedroomsItems = [
  {
    label: 'Any',
    value: 0,
    ariaLabel: 'Bedroom Option',
  },
  {
    label: '1+',
    value: 1,
    ariaLabel: 'Bedroom Option',
  },
  {
    label: '2+',
    value: 2,
    ariaLabel: 'Bedroom Option',
  },
  {
    label: '3+',
    value: 3,
    ariaLabel: 'Bedroom Option',
  },
  {
    label: '4+',
    value: 4,
    ariaLabel: 'Bedroom Option',
  },
];

export const bathroomsItems = [
  {
    label: 'Any',
    value: 0,
    ariaLabel: 'Bathroom Option',
  },
  {
    label: '1+',
    value: 1,
    ariaLabel: 'Bathroom Option',
  },
  {
    label: '2+',
    value: 2,
    ariaLabel: 'Bathroom Option',
  },
  {
    label: '3+',
    value: 3,
    ariaLabel: 'Bathroom Option',
  },
  {
    label: '4+',
    value: 4,
    ariaLabel: 'Bathroom Option',
  },
];

export const poolTypeItems = Object.values(PoolType).map((value) => ({
  value,
  label: value.split('_').map(capitalize).join(' '),
}));

export type MarketItem = {
  label: string;
  value: Uppercase<MarketLocation>;
  ariaLabel: string;
};

// TODO: move this into ../helpers/exclusives
export const marketItems: MarketItem[] = [
  {
    label: 'Dallas',
    value: 'DALLAS',
    ariaLabel: 'City',
  },
  {
    label: 'Raleigh-Durham',
    value: 'RALEIGH',
    ariaLabel: 'City',
  },
  {
    label: 'Charlotte',
    value: 'CHARLOTTE',
    ariaLabel: 'City',
  },
];

export type FeedPropertiesFilter = {
  id: string;
  price: {
    min: {
      value: number;
    };
    max: {
      value: number;
    };
  };
  bedrooms: {
    min: number | null;
  };
  bathrooms: {
    min: number | null;
  };
  sqft: {
    min: number | null;
    max: number | null;
  };
  lotSqft: {
    min: number | null;
    max: number | null;
  };
  yearBuilt: {
    min: number | null;
    max: number | null;
  };
  propertyTypes: PropertyTypeValues[] | null;
  zoneIds: string[];
  origin: OriginValues | null;
};

// This is the default filter returned from athena for a new user
export const FEED_PROPERTIES_FILTER_ATHENA_API_DEFAULT_RESPONSE: FeedPropertiesFilter = {
  id: '',
  price: {
    min: {
      value: 0,
    },
    max: {
      value: 999999999,
    },
  },
  bedrooms: {
    min: null,
  },
  bathrooms: {
    min: null,
  },
  sqft: {
    min: null,
    max: null,
  },
  lotSqft: {
    min: null,
    max: null,
  },
  yearBuilt: {
    min: null,
    max: null,
  },
  propertyTypes: ['PROPERTY_TYPE_HOME', 'PROPERTY_TYPE_CONDO', 'PROPERTY_TYPE_TOWNHOME'],
  zoneIds: [],
  origin: 'SEARCH_ORIGIN_BUYER',
};

// This is being necessary to handle the initial filter from a new user
// and properly allow us to set the initial filter with proper values
export function isFeedPropertiesFilterFromAthenaEqualToTheInitial(
  feedPropertiesFilter: FeedPropertiesFilter,
) {
  return isEqual(feedPropertiesFilter, FEED_PROPERTIES_FILTER_ATHENA_API_DEFAULT_RESPONSE);
}

export function mergeFilters(filters: Filters, partialFilters: Partial<Filters>) {
  return Object.entries(partialFilters).reduce((acc, [key, val]) => {
    if (val) {
      (acc[key as keyof Filters] as any) = val;
    }
    return acc;
  }, cloneDeep(filters));
}

const FILTER_PREFERENCE_IS_ENABLED: Record<keyof Filters, boolean> = {
  priceRangeMax: true,
  priceRangeMin: true,
  bedrooms: true,
  bathrooms: true,
  buildingSizeMax: true,
  buildingSizeMin: true,
  lotSizeMax: true,
  lotSizeMin: true,
  yearBuiltMax: true,
  yearBuiltMin: true,
  neighborhoods: true,
  propertyTypes: true,
  homeStatus: true,
  sellerType: true,
  listedWhereStatus: true,
};

const FILTER_PREFERENCE_KEYS: (keyof Filters)[] = (
  Object.entries(FILTER_PREFERENCE_IS_ENABLED) as [keyof Filters, boolean][]
)
  .filter(([_, isEnabled]) => isEnabled)
  .map(([filterName]) => filterName);

function customIsEqualForFilters(a: Filters, b: Filters) {
  return Object.entries(a).every(([key, val]) => {
    const bVal = b[key as keyof Filters];
    if (Array.isArray(val) && Array.isArray(bVal)) {
      return val.length === bVal.length && !difference(val, bVal).length;
    }
    return isEqual(val, bVal);
  });
}

export function isEqualFilters(filtersA: Filters, filtersB: Filters) {
  return isEqualWith(
    pick(filtersA, FILTER_PREFERENCE_KEYS),
    pick(filtersB, FILTER_PREFERENCE_KEYS),
    customIsEqualForFilters,
  );
}

export function isPreferencesOrFiltersOutdated(
  filters: Filters,
  athenaPreferencesFilters?: Filters,
) {
  const isEqualInitialFilters = isEqualFilters(filters, INITIAL_FILTERS);
  return athenaPreferencesFilters
    ? !isEqualInitialFilters && !isEqualFilters(filters, athenaPreferencesFilters)
    : !isEqualInitialFilters;
}

export function filtersDataFromFeedPropertiesFilter(
  market: Uppercase<MarketLocation>,
  feedPropertiesFilter: FeedPropertiesFilter,
): {
  filters: Filters;
  selectedNeighborhoods: NeighborhoodInfo[] | undefined;
} {
  const neighborhoods = NEIGHBORHOODS_KEYED_BY_UPPERCASE_MARKET[market];
  // neighborhoods are stored as UUIDs in athena so find the full neighborhood objects
  // that correspond to them (so we can use in query params)
  let selectedNeighborhoods;
  if (feedPropertiesFilter['zoneIds']) {
    const selectedNeighborhoodUuidsSet = new Set(feedPropertiesFilter['zoneIds']);
    if (selectedNeighborhoodUuidsSet.size !== neighborhoods.length) {
      selectedNeighborhoods = neighborhoods.filter((neighborhood) =>
        selectedNeighborhoodUuidsSet.has(neighborhood.uuid as string),
      );
    }
  }
  let propertyTypes =
    feedPropertiesFilter['propertyTypes']?.filter((pt) =>
      PROPERTY_TYPE_STATUS_ARRAY.includes(pt),
    ) || [];
  if (propertyTypes.length && propertyTypes.length === PROPERTY_TYPE_STATUS_ARRAY.length) {
    propertyTypes = [];
  }
  const filters = isFeedPropertiesFilterFromAthenaEqualToTheInitial(feedPropertiesFilter)
    ? cloneDeep(INITIAL_FILTERS)
    : mergeFilters(INITIAL_FILTERS, {
        priceRangeMin: feedPropertiesFilter['price']['min']['value'],
        priceRangeMax:
          !feedPropertiesFilter['price']['max']['value'] ||
          feedPropertiesFilter['price']['max']['value'] ===
            FEED_PROPERTIES_FILTER_ATHENA_API_DEFAULT_RESPONSE.price.max.value
            ? 0
            : feedPropertiesFilter['price']['max']['value'],
        bathrooms: feedPropertiesFilter['bathrooms']['min'] || 0,
        bedrooms: feedPropertiesFilter['bedrooms']['min'] || 0,
        buildingSizeMin: feedPropertiesFilter['sqft']['min'] || 0,
        buildingSizeMax: feedPropertiesFilter['sqft']['max'] || 0,
        lotSizeMin: feedPropertiesFilter['lotSqft']['min'] || 0,
        lotSizeMax: feedPropertiesFilter['lotSqft']['min'] || 0,
        yearBuiltMin: feedPropertiesFilter['yearBuilt']['min'] || 0,
        yearBuiltMax: feedPropertiesFilter['yearBuilt']['max'] || 0,
        propertyTypes,
        neighborhoods: selectedNeighborhoods?.map(({ id }) => id) || [],
      });

  return {
    selectedNeighborhoods,
    filters,
  };
}

export function getSelectedNeighborhoods(
  neighborhoodIdentifiers: string[] | undefined,
  marketNeighborhoods: NeighborhoodInfo[],
) {
  if (!neighborhoodIdentifiers) return [];
  const neighborHoodsSet = new Set(neighborhoodIdentifiers);
  return marketNeighborhoods.filter(({ id }) => neighborHoodsSet.has(id));
}
