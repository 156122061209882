/* storybook-check-ignore */
import { createContext, useContext, useEffect, useMemo } from 'react';

import difference from 'lodash/difference';
import get from 'lodash/get';
import useSWR, { SWRConfiguration } from 'swr';
import useSWRMutation from 'swr/mutation';

import { doBFFPost } from 'components/api';
import { ATHENA_URL, EXCLUSIVE_RAILS_HOST } from 'components/globals';
import AccountConnector, { AccountProps } from 'components/shared/AccountConnector';

import { MarketLocation } from 'declarations/exclusives/market';

import {
  Filters,
  PROPERTY_TYPE_STATUS_ARRAY,
  PropertyTypeValues,
} from 'helpers/exclusives/filterHomes';
import { NeighborhoodInfo } from 'helpers/exclusives/neighborhoods';

import useExclusiveAnalytics from '../hooks/useExclusiveAnalytics';
import {
  DEFAULT_MARKET_IDENTIFIER,
  FEED_PROPERTIES_FILTER_ATHENA_API_DEFAULT_RESPONSE,
  FeedPropertiesFilter,
  filtersDataFromFeedPropertiesFilter,
  getSelectedNeighborhoods,
  NEIGHBORHOODS_KEYED_BY_UPPERCASE_MARKET,
} from './utils';

const PREFERENCES_GRAPHQL_QUERY = `
  query GetBuyer($customerUUID: String!) {
    buyerService {
      getBuyer(input: { customerId: $customerUUID }) {
        buyer {
          selectedMarket
          savedSearches {
            price {
              min {
                value
              }
              max {
                value
              }
            }
            bedrooms {
              min
            }
            bathrooms {
              min
            }
            sqft {
              min
              max
            }
            lotSqft {
              min
              max
            }
            yearBuilt {
              min
              max
            }
            propertyTypes
            zoneIds
            origin
          }
        }
      }
    }
  }
`;

interface GetBuyer {
  buyer: {
    selectedMarket: Uppercase<MarketLocation>;
    savedSearches: Array<FeedPropertiesFilter>;
  };
}

interface AthenaPreferencesData {
  market: Uppercase<MarketLocation>;
  selectedNeighborhoods?: NeighborhoodInfo[];
  filters: Filters;
  feedPropertiesFilter: FeedPropertiesFilter;
}

const ATHENA_PREFERENCES_LS_KEY = '@cosmos/exclusives/AthenaPreferencesData';

function savePreferencesOnLS(data: AthenaPreferencesData) {
  if (typeof window !== 'undefined') {
    localStorage.setItem(ATHENA_PREFERENCES_LS_KEY, JSON.stringify(data));
  }
}

function loadPreferencesFromLS(): AthenaPreferencesData | undefined {
  if (typeof window == 'undefined') {
    return undefined;
  }
  const preferencesString = localStorage.getItem(ATHENA_PREFERENCES_LS_KEY);
  return preferencesString ? JSON.parse(preferencesString) : undefined;
}

export async function loadPreferencesFromAthena(
  customerUUID: string,
  market?: Uppercase<MarketLocation>,
): Promise<AthenaPreferencesData> {
  const response = await doBFFPost(
    ATHENA_URL,
    {
      operationName: null,
      query: PREFERENCES_GRAPHQL_QUERY,
      variables: { customerUUID },
    },
    { headers: { 'apollographql-client-name': 'cosmos-exclusives' }, retries: 0 },
  );
  const body = await response.json();
  const user: GetBuyer['buyer'] | undefined = get(body, 'data.buyerService.getBuyer.buyer');
  const feedPropertiesFilter =
    user?.savedSearches?.find((search) => search.origin !== 'SEARCH_ORIGIN_BUYERS_AGENT') ||
    FEED_PROPERTIES_FILTER_ATHENA_API_DEFAULT_RESPONSE;
  const marketIdentifier =
    market ||
    (user &&
    Object.keys(NEIGHBORHOODS_KEYED_BY_UPPERCASE_MARKET).indexOf(user.selectedMarket) !== -1
      ? user.selectedMarket
      : DEFAULT_MARKET_IDENTIFIER);
  const { filters, selectedNeighborhoods } = filtersDataFromFeedPropertiesFilter(
    marketIdentifier,
    feedPropertiesFilter,
  );
  savePreferencesOnLS({
    market: marketIdentifier,
    selectedNeighborhoods,
    filters,
    feedPropertiesFilter,
  });
  return {
    market: marketIdentifier,
    selectedNeighborhoods,
    filters,
    feedPropertiesFilter,
  };
}

export function useAthenaPreferences({
  market,
  trackExclusivesEvent,
  scope,
  saveAnalyticsLabel = 'save-preferences',
  onSave,
  account,
  ...props
}: {
  account: AccountProps;
  market?: Uppercase<MarketLocation>;
  trackExclusivesEvent: ReturnType<typeof useExclusiveAnalytics>['trackExclusivesEvent'];
  scope: 'gallery' | 'preferences' | 'saved-homes';
  saveAnalyticsLabel?: string;
  onSave?: () => void;
} & SWRConfiguration<AthenaPreferencesData | undefined>) {
  const fallbackData = useMemo(loadPreferencesFromLS, []);
  const customerUUID = account.human?.get('customer_uuid');
  const enabled = account.infoIsLoaded && account.loggedIn && customerUUID;
  const isUnauthenticated = account.infoIsLoaded && !account.loggedIn;
  const { data, error, ...other } = useSWR<AthenaPreferencesData | undefined>(
    enabled && [ATHENA_URL, PREFERENCES_GRAPHQL_QUERY],
    () => loadPreferencesFromAthena(customerUUID, market),
    {
      fallbackData,
      ...props,
    },
  );
  const { trigger: save, isMutating: mutateIsLoading } = useSWRMutation(
    [ATHENA_URL, PREFERENCES_GRAPHQL_QUERY],
    async (
      _,
      {
        arg: { market, filters, options = {} },
      }: {
        arg: {
          market: Uppercase<MarketLocation>;
          filters: Filters;
          options?: { saveAnalyticsLabel?: string; disableTracking?: boolean };
        };
      },
    ) => {
      const marketNeighborhoods = NEIGHBORHOODS_KEYED_BY_UPPERCASE_MARKET[market];
      const [filtersResp] = await Promise.all([
        savePreferencesToAthena(
          customerUUID,
          filters.priceRangeMin,
          filters.priceRangeMax,
          filters.bedrooms,
          filters.bathrooms,
          filters.buildingSizeMin,
          filters.buildingSizeMax,
          filters.lotSizeMin,
          filters.lotSizeMax,
          filters.yearBuiltMin,
          filters.yearBuiltMax,
          getProperTypesToSave(filters, data?.feedPropertiesFilter),
          (!filters.neighborhoods.length
            ? marketNeighborhoods
            : getSelectedNeighborhoods(filters.neighborhoods, marketNeighborhoods)
          ).map((neighborhood) => neighborhood.uuid as string),
        ),
        saveMarketToAthena(market, customerUUID),
        enrollExclusiveCustomer(),
      ]);
      const marketIdentifier =
        Object.keys(NEIGHBORHOODS_KEYED_BY_UPPERCASE_MARKET).indexOf(market) !== -1
          ? (market as Uppercase<MarketLocation>)
          : DEFAULT_MARKET_IDENTIFIER;
      const feedPropertiesFilter: FeedPropertiesFilter = await filtersResp
        .json()
        .then((body) => get(body, 'data.buyer.upsertPropertiesFilterByCustomerUUID'));
      const { filters: savedFilters, selectedNeighborhoods } = filtersDataFromFeedPropertiesFilter(
        marketIdentifier,
        feedPropertiesFilter,
      );
      const response = {
        market: marketIdentifier,
        filters: savedFilters,
        selectedNeighborhoods,
        feedPropertiesFilter,
      };
      if (!options.disableTracking) {
        trackExclusivesEvent(
          'exclusives-preferences',
          options.saveAnalyticsLabel || saveAnalyticsLabel,
          undefined,
          {
            market: response.market,
            filters: response.filters,
            selectedNeighborhoods: response.selectedNeighborhoods?.map(({ id }) => id),
            scope,
          },
        );
      }
      savePreferencesOnLS(response);
      onSave?.();
      return response;
    },
  );
  // Cleanup Local Storage when unauthenticated
  useEffect(() => {
    if (isUnauthenticated) {
      localStorage.removeItem(ATHENA_PREFERENCES_LS_KEY);
    }
  }, [isUnauthenticated]);
  return {
    isError: !!error,
    error,
    data,
    save,
    ...other,
    isLoading: mutateIsLoading || (!data && !error),
    isFetching: other.isLoading,
  };
}

function getProperTypesToSave(
  filters: Filters,
  feedPropertiesFilter: FeedPropertiesFilter | undefined,
) {
  const selectedPropertyTypesOnExclusives = filters.propertyTypes?.length
    ? filters.propertyTypes
    : PROPERTY_TYPE_STATUS_ARRAY; // These values are all values available/considered on the Exclusives filter
  const selectedPropertyTypesOutsideOfExclusives = feedPropertiesFilter?.propertyTypes
    ? difference(feedPropertiesFilter.propertyTypes, PROPERTY_TYPE_STATUS_ARRAY)
    : [];
  return [...selectedPropertyTypesOnExclusives, ...selectedPropertyTypesOutsideOfExclusives];
}

const enrollExclusiveCustomer = async () => {
  return await doBFFPost(
    `${EXCLUSIVE_RAILS_HOST}/api/v1/exclusives/enroll-el-customer`,
    {
      enrollment_reason: 'set_preferences_on_exclusives',
    },
    { retries: 0 },
    // This is probably a CORS error
  ).catch(console.error);
};

export const saveMarketToAthena = async (
  market: Uppercase<MarketLocation>,
  customerUUID: string,
) => {
  return await doBFFPost(
    ATHENA_URL,
    {
      operationName: null,
      query: `mutation SetMarket($market: OdProtosCommonMarket_Market!, $customerUUID: String!) {
        buyerService {
          setSelectedMarket(input: { market: $market, customerId: $customerUUID }) {
            _empty
          }
        }
      }`,
      variables: { market, customerUUID },
    },
    { headers: { 'apollographql-client-name': 'cosmos-exclusives' }, retries: 0 },
  );
};

function valueOrIfZeroNull(val: number) {
  return val || null;
}

const savePreferencesToAthena = async (
  customerUUID: string,
  minPrice: number,
  maxPrice: number,
  minBedrooms: number,
  minBathrooms: number,
  minSqft: number,
  maxSqft: number,
  minLotSqft: number,
  maxLotSqft: number,
  minYearBuilt: number,
  maxYearBuilt: number,
  propertyTypes: PropertyTypeValues[],
  zoneIds: string[],
) => {
  return await doBFFPost(
    ATHENA_URL,
    {
      operationName: null,
      query: `mutation UpsertFilters($customerUUID: String!, $minPrice: Float, $maxPrice: Float, $minBedrooms: Float, $minBathrooms: Float, $minSqft: Float, $maxSqft: Float, $minLotSqft: Float, $maxLotSqft: Float, $minYearBuilt: Float, $maxYearBuilt: Float, $propertyTypes: [OdProtosBuyerV2Data_PropertyType!]!, $zoneIds: [String!]!) {
  buyer {
    upsertPropertiesFilterByCustomerUUID(
      customerUUID: $customerUUID
      filter: {
        price: { min: { value: $minPrice }, max: { value: $maxPrice } }
        bedrooms: { min: $minBedrooms }
        bathrooms: { min: $minBathrooms }
        sqft: { min: $minSqft, max: $maxSqft }
        lotSqft: { min: $minLotSqft, max: $maxLotSqft }
        yearBuilt: { min: $minYearBuilt, max: $maxYearBuilt }
        propertyTypes: $propertyTypes
        zoneIds: $zoneIds
        origin: SEARCH_ORIGIN_BUYER
      }
    ) {
      id
      origin
      price {
        min {
          value
        }
        max {
          value
        }
      }
      bedrooms {
        min
      }
      bathrooms {
        min
      }
      sqft {
        min
        max
      }
      lotSqft {
        min
        max
      }
      yearBuilt {
        min
        max
      }
      propertyTypes
      zoneIds
    }
  }
}`,
      variables: {
        customerUUID,
        minPrice,
        maxPrice: maxPrice || FEED_PROPERTIES_FILTER_ATHENA_API_DEFAULT_RESPONSE.price.max.value,
        minBedrooms,
        minBathrooms,
        minSqft,
        maxSqft: valueOrIfZeroNull(maxSqft),
        minLotSqft,
        maxLotSqft: valueOrIfZeroNull(maxLotSqft),
        minYearBuilt,
        maxYearBuilt: valueOrIfZeroNull(maxYearBuilt),
        propertyTypes,
        zoneIds,
      },
    },
    { headers: { 'apollographql-client-name': 'cosmos-exclusives' }, retries: 0 },
  );
};

export type UseAthenaPreferences = typeof useAthenaPreferences;

export const AthenaPreferencesContext = createContext({} as ReturnType<UseAthenaPreferences>);

export const useAthenaPreferencesContext = () => useContext(AthenaPreferencesContext);

type AthenaPreferencesProviderProps = AccountProps &
  Pick<
    Parameters<UseAthenaPreferences>[0],
    'market' | 'scope' | 'saveAnalyticsLabel' | 'onSave'
  > & {
    children: JSX.Element | JSX.Element[];
  };

const _AthenaPreferencesProvider = ({
  children,
  market,
  scope,
  saveAnalyticsLabel,
  onSave,
  ...account
}: AthenaPreferencesProviderProps) => {
  const { trackExclusivesEvent } = useExclusiveAnalytics();
  const athenaPreferences = useAthenaPreferences({
    account,
    market,
    scope,
    saveAnalyticsLabel,
    trackExclusivesEvent,
    onSave,
  });
  return (
    <AthenaPreferencesContext.Provider value={athenaPreferences}>
      {children}
    </AthenaPreferencesContext.Provider>
  );
};

export const AthenaPreferencesProvider = AccountConnector(_AthenaPreferencesProvider);
